import React from "react";
import { motion } from "framer-motion";

const Accordion = ({ faq, expanded, setExpanded }) => {
  const isOpen = faq.id === expanded;
  return (
    <div
      className="accordion max-w-xs sm:max-w-3xl  mb-20 box-border	"
      onClick={() => setExpanded(isOpen ? false : faq.id)}
    >
      <div className="accordion-header">
        <p className="max-w-2xl font-bold">{faq.question}</p>
        <div className="accordion-icon">
          <motion.span
            animate={{ rotate: isOpen ? 0 : 0, x: 4 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
          <motion.span
            animate={{ rotate: isOpen ? 0 : -90, x: -12 }}
            transition={{ duration: 0.2, ease: [0.6, 0.05, -0.01, 0.9] }}
          ></motion.span>
        </div>
      </div>
      <motion.div
        key="content"
        animate={{ height: isOpen ? "100%" : "0" }}
        transition={{ duration: 0.9, ease: [0.6, 0.05, -0.01, 0.9] }}
        className="accordion-content"
      >
        <span dangerouslySetInnerHTML={{__html: faq.answer}} />
      </motion.div>
    </div>
  );
};

export default Accordion;
