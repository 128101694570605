import React, { useContext } from "react";
import { BreakpointContext } from "../context/AppProvider";

const GetCertificated = () => {
  const { width, breakpointDesktop, breakpointMobile } =
    useContext(BreakpointContext);
  return (
    <section
      className={
        width > breakpointMobile
          ? "min-h-[1200px] max-h-[1200px] flex justify-center items-center flex-col text-center"
          : "min-h-[800px] max-h-[1200px] px-12 bg-nowait-bg flex justify-end items-center flex-col text-center"
      }
    >
      {width > breakpointMobile ? (
        <img
          className="bg-nowait"
          src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986631/ukodesh-landing/Group_28_gzngn5.png"
        />
      ) : null}
      <h1 className="text-4xl sm:text-6xl font-bold mb-12 text-white">
        ¡No esperes más y obtén <br /> tu certificación de inglés!
      </h1>
      <p className="mb-16 text-xl font-semibold max-w-xl text-white">
        Nuestras propuestas están diseñadas para brindarte el máximo éxito en tu
        proceso. <br />
        Elige la alternativa que más te convenga y da el siguiente paso para
        impulsar tus metas profesionales.
      </p>
    </section>
  );
};

export default GetCertificated;
