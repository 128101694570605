import React, {useContext} from "react";
import { BreakpointContext } from "../context/AppProvider";
import ScheduleDesktop from "./ScheduleDesktop";
import ScheduleMobile from "./ScheduleMobile";


const Schedule = () => {
  const { width, breakpointDesktop, breakpointMobile } =
    useContext(BreakpointContext);

  return (
    <>
    {width > breakpointMobile ? <ScheduleDesktop /> : <ScheduleMobile />}
    </>
  );
};

export default Schedule;
