import React, { useState, useEffect } from "react";
import "./App.scss";
import HeroBanner from "./components/HeroBanner";
import VideoBanner from "./components/VideoBanner";
import GetCertificated from "./components/GetCertificated";
import Schedule from "./components/Schedule";
import Plans from "./components/Plans";
import Note from "./components/Note";
import FAQs from "./components/FAQs";
import Reviews from "./components/Reviews";
import Chat from "./components/Chat";

function App() {
  const [scrollTop, setScrollTop] = useState(0);

  const onScroll = () => {
    const widthScroll = document.documentElement.scrollTop;
    const height =
      document.documentElement.scrollHeight -
      document.documentElement.clientHeight;
    const scrolled = (widthScroll / height) * 100;
    setScrollTop(scrolled);
  };

  useEffect(() => {
    window.addEventListener("scroll", onScroll);
    return () => window.removeEventListener("scroll", onScroll);
  }, []);

  return (
    <div className="App">
      <div className="loader-progress">
        <div className="loader" style={{ width: `${scrollTop}%` }} />
      </div>
      <main className="landing-sells">
        <HeroBanner />
        <VideoBanner />
        <GetCertificated />
        <Schedule />
        <Plans />
        <Note />
        <FAQs />
        <Reviews />
        <Chat />
      </main>
      <footer></footer>
    </div>
  );
}

export default App;
