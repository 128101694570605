import React from "react";
import { Slide } from "react-slideshow-image";
import { StarIcon, ArrowDownTrayIcon } from "@heroicons/react/24/solid";

import quick from "../docs/ukodesh-quick-certification.pdf";
import exclusive from "../docs/ukodesh-exclusive-certification.pdf";
import professional from "../docs/ukodesh-professional-certification.pdf";

const reviews = [
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_2_m0fb8j.png",
    title: "Quick Certification Package",
    desc: "“Por segunda ocasión lleve mi proceso de certificación con UKODESH EDUCATION, y al igual que la primera vez siempre estuvieron atentos y resolviendo todas mis dudas.”",
    name: "Diana Barrón",
    location: "Chiapas",
    package_desc:
      "<br>El <b>Quick Certification Package</b> es la elección perfecta para aquellos candidatos que desean obtener su certificación de inglés de manera eficaz y sin complicaciones.<br /><br />Este paquete está especialmente diseñado para quienes ya han certificado anteriormente y desean realizar la gestión CENNI por su cuenta sin costo adicional, ya que el trámite CENNI es gratuito si lo realiza directamente el candidato. <br /><br /><b className='mt-4'>Obtén tu certificación de manera ágil y confiable.</b>",
    pdfLink: quick,
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_8_xrjeyu.png",
    title: "Exclusive Certification Package",
    desc: "“El feedback que me proporcionaron de mi examen previo al de certificación evitó que quedara en un nivel inferior al que buscaba, tome el curso de preparación y gracias a eso obtuve el nivel C1 y la validez CENNI por 5 años.”",
    name: "Fernando García",
    location: "Monterrey",
    package_desc:
      "El <b>Exclusive Certification Package</b> está diseñado para candidatos que desean un proceso sin preocupaciones. Incluye una evaluación en línea con retroalimentación previa para ayudarte a medir tu nivel de inglés. Esta opción está dirigida a candidatos que buscan <b> certificar su nivel de inglés por primera vez y desean conocer su posición actual antes del examen, </b> lo que les permite visualizar cuán cerca o lejos están de su objetivo. <span className='mt-4'> <b>Nos encargamos de la gestión CENNI por ti,</b> eliminando el estrés de estar lidiando con documentos y trámites administrativos. Con esta opción, puedes enfocarte únicamente en certificar tu nivel de inglés, con confianza y tranquilidad. </span>",
    pdfLink: exclusive,
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992987/ukodesh-landing/Group_9_qpvzy9.png",
    title: "Professional Certification Upgrade",
    desc: "“Sin duda un proceso sencillo el llevarlo desde la comodidad de mi casa y recibir mi certificado vía fedex en mi domicilio me ahorró mucho tiempo. Agradezco la atención por parte de mi asesor siempre me mantuvo tranquila.”",
    name: "Daniela Rodríguez",
    location: "Ensenada",
    package_desc:
      "<b>¡Prepárate para alcanzar un nivel específico de inglés con nuestro curso de preparación personalizado! </b> <br /> <br /> Comenzamos evaluando tu nivel actual a través de un assessment, y a partir de allí, te asignamos un curso de  inglés adaptado a tus necesidades. Nuestro curso de preparación consta de 120 horas por nivel, con una duración  de 3 meses. Accede a nuestro campus online y nuestra app 100% en línea las 24 horas del día, los 7 días de la semana. <br /> <br /> Una vez que hayas completado el curso, realizaremos otro assessment para asegurarnos de que estés avanzando y acercándote a tu objetivo de certificación. <br /> <br /> <b className='mt-4'> Después, podrás programar tu examen para obtener la certificación SDSU Leaders y certificación CENNI.</b>",
    pdfLink: professional,
  },
];

const ScheduleMobile = () => {
  return (
    <section className="mt-12 mb-28">
      <div className="flex flex-col rounded-lg justify-center items-center mb- p-12  w-full">
        <h1 className="text-4xl sm:text-6xl font-bold mb-6 text-center">
          ¡Programa tu examen hoy mismo!
        </h1>
        <p className="max-w-[600px] mb-2 font-semibold text-center">
          Explora nuestras propuestas diseñadas para maximizar tu éxito.
          Selecciona la alternativa que se adapte a ti y da el siguiente paso
          hacia tus objetivos profesionales
        </p>
      </div>
      <Slide autoplay={false} indicators={true}>
        {reviews.map((review, index) => (
          <div className="flex justify-center items-center p-2" key={index}>
            <div className="flex justify-center flex-col text-center items-center bg-white max-w-lg p-10">
              <div className="flex flex-col border-b pb-5 mb-5">
                <h1 className="font-bold text-4xl mb-3">{review.title}</h1>
                <p
                  className="text-md mt-4 sm:text-left text-center mb-12 sm:mb-0"
                  dangerouslySetInnerHTML={{ __html: review.package_desc }}
                />
                <a
                  style={{ backgroundColor: "#4F61FF" }}
                  className="text-white flex p-5 mt-1 rounded-md"
                  href={review.pdfLink}
                  target="_blank"
                >
                  <ArrowDownTrayIcon width={20} fill="white" className="mr-4" />
                  Descubre el camino hacía tu certificación
                </a>
              </div>
              <img className="max-w-[140px] mt-12 mb-2" src={review.picture} alt="" />
              <div className="flex">
                <StarIcon className="mr-2" width={16} fill="#FFD059" />
                <StarIcon className="mr-2" width={16} fill="#FFD059" />
                <StarIcon className="mr-2" width={16} fill="#FFD059" />
                <StarIcon className="mr-2" width={16} fill="#FFD059" />
                <StarIcon width={16} fill="#FFD059" />
              </div>
              <p className="max-w-[300px] text-center mt-6">{review.desc}</p>
              <p className="mt-3">{review.name}</p>
            </div>
          </div>
        ))}
      </Slide>
    </section>
  );
};

export default ScheduleMobile;
