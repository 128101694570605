import React from 'react'
import { ChevronDoubleDownIcon } from '@heroicons/react/24/solid'
import video from "../media/sdsu.mp4";


const VideoBanner = () => {
  return (
    <section className="flex flex-col justify-center items-center pb-20 px-12 sm:px-0">
          <ChevronDoubleDownIcon
            className="mt-12 mb-12 fill-white bg-yellow-400 animate-bounce rounded-full p-2"
            width={48}
          />
          <h1 className="text-4xl sm:text-6xl px-6 text-center mb-6 font-bold">
            ¡Certifica tu nivel de Inglés!
          </h1>
          <h1 className="text-lg sm:text-2xl text-center font-bold mb-12">
            Con nuestro examen de Certificación
          </h1>
          <img
            className="max-w-[280px] mb-20"
            src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986630/ukodesh-landing/sdsu_primary_logo_rgb_horizontal_full_color_1_1_hfzd32.png"
          />
          <div>
            <div className="bg-video-yellow" />
            <video className="mb-12 rounded-xl" width={700} controls>
              <source src={video} type="video/mp4" />
            </video>
          </div>
          <div className="flex flex-col sm:flex-row items-center justify-center">
            <a
              style={{ backgroundColor: "#4F61FF" }}
              className="text-white p-5 mt-8 sm:mt-0 rounded-md"
              href="#plans"
            >
              Programa tu examen hoy
            </a>
          </div>
        </section>
  )
}

export default VideoBanner