import React from "react";
import { CheckIcon } from "@heroicons/react/24/solid";

const Plan = ({
  title,
  features,
  price,
  PayLink,
  bgColor,
  borderColor,
  priceLined,
  purpled,
  centered
}) => {
  return (
    <div
      style={{ background: bgColor, border: `1px solid ${borderColor}` }}
      className={centered ? '-mt-12 mb-12 lg:mb-0 rounded-xl p-6 flex max-w-[396px] w-full justify-between items-center flex-col mr-0 sm:mr-12' : 'rounded-xl mb-32 lg:mb-0 p-6 flex max-w-[396px] w-full justify-between items-center flex-col mr-0 sm:mr-12'}
    >
        {purpled ? <div style={{color:'#4F61FF'}} className="rounded-full bg-white px-4 py-1 text-xs absolute -mt-9">Más popular</div> : null}
      <h1
        style={{ color: borderColor }}
        className="text-2xl mt-1 font-bold max-w-[260px] mb-8 text-center"
      >
        {title}
      </h1>
      <div className="features flex flex-col">
        {Object.values(features).map((feature) => (
          <div className="flex mb-1">
            <CheckIcon className="mr-4 pb-6 text-bold" width={18} fill={borderColor} />
            <p style={{color: purpled ? 'white' : null}} className="max-w-[200px] text-start" dangerouslySetInnerHTML={{__html: feature}} />
          </div>
        ))}
      </div>
      <div className="price my-12 flex flex-col">
        <h1 style={{color:purpled ? 'white' : null}} className="text-2xl font-bold">{price}</h1>
        <h1  style={{color:purpled ? 'white' : borderColor}} className="text-2xl font-bold">Precio especial</h1>
        <p style={{color: purpled ? 'white' : null}} className="line-through mt-6 text-xs text-grey-200">{priceLined}</p>
        <p style={{color: purpled ? 'white' : null}} className="mt-1 text-xs text-grey-200 max-w-[240px]">Paga cómodamente hasta en 12 plazos fijos con tu tarjeta de crédito</p>
      </div>
      <a style={{background:borderColor, color:bgColor, cursor:'pointer'}} target="_blank" href={PayLink} className="rounded-lg p-4 font-semibold">Programa tu examen hoy mismo</a>

    </div>
  );
};

export default Plan;
