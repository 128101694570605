import React, { useState, useEffect, useContext } from "react";
import { BreakpointContext } from "../context/AppProvider";

const HeroBanner = () => {
  const { width, breakpointDesktop, breakpointMobile } =
    useContext(BreakpointContext);

  return (
    <section className="flex hero-banner px-10 flex-col min-h-[600px] justify-center sm:justify-between items-center sm:flex-row">
      {width > breakpointMobile ? (
        <img
          className="hero-right-bg min-h-[830px] lg:max-h-[830px]"
          src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986631/ukodesh-landing/Group_22_hahutr.png"
        />
      ) : null}
      <div className="w-full flex justify-center items-center ml-12 sm:ml-0">
        <img
          className="w-[326px] sm:w-full h-full"
          src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986631/ukodesh-landing/Group_6_vmw2db.png"
          alt=""
        />
      </div>
      <div className="flex justify-center w-full">
        <div className="flex max-w-sm flex-col items-center sm:items-end p-10 sm:p-0">
          <h1 className="text-2xl sm:text-4xl text-center sm:text-right text-white font-bold max-w-md mb-6">
            ¡OBTEN TU CERTIFICACIÓN INTERNACIONAL DE INGLÉS!
          </h1>
          <div className="flex justify-between">
            <div className="flex flex-col justify-center mr-20 items-center sm:items-start sm:justify-start">
              <span className="white text-bold mb-2">Avalado por</span>
              <img
                className="max-w-[120px]"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986630/ukodesh-landing/sdsu_primary_logo_rgb_stacked_1-color_white_1_yplhh9.png"
                alt=""
              />
            </div>
            <div className="flex flex-col items-center sm:items-start sm:justify-start">
              <span className="white text-bold mb-2">Con validez</span>
              <img
                className="max-w-[120px]"
                src="https://res.cloudinary.com/droimgtqi/image/upload/v1698890813/ukodesh-media/cenni_c3edvj.png"
                alt=""
              />
            </div>
          </div>
          <a
            style={{ backgroundColor: "#4F61FF" }}
            className="text-white p-5 mt-12 rounded-md"
            href="#plans"
          >
            Programa tu examen hoy
          </a>
        </div>
      </div>
    </section>
  );
};

export default HeroBanner;
