import React, { useState, useEffect } from "react";
import Accordion from "./Accordion";

const faqs = [
  {
    id: 0,
    question: "¿Cómo puedo prepararme para la certificación?",
    answer:
      "El examen  es multi nivel , esto significa que te va evaluar un inglés del A1 básico  hasta el C1 avanzado,  certifica un inglés general, y todo alumno recibe su certificado de acuerdo a su nivel obtenido. por lo tanto no se requiere preparación previa. Sin embargo te proporcionaremos una guía rápida  y sample test para que te familiarices con la estructura del examen y plataforma.",
  },
  {
    id: 1,
    question: "¿Cuándo y cómo recibiré mi certificado físico?",
    answer:
      "Después de recibir tus resultados digitales, el certificado físico será enviado a tu domicilio a través de FedEx en un plazo de 5 a 10 días hábiles adicionales.",
  },
  {
    id: 2,
    question:
      "¿Mi certificado SDSU LEADERS tiene fecha de vencimiento?",
    answer:
      "No, el certificado únicamente indica la fecha en que fue emitido.",
  },
  {
    id: 3,
    question:
      "¿El certificado SDSU tiene validez ante la SEP CENNI?",
    answer:
      "Sí, nuestro certificado cuenta con la validación de la Secretaría de Educación Pública (SEP) y cumple con los estándares del Marco Común Europeo de Referencia (MCER). Puedes verificar su autenticidad directamente en la página oficial a través del siguiente enlace: <a href='https://dgair.sep.gob.mx/storage/recursos/CENNI/sdsu_leaders.pdf' target='_blank'><b>https://dgair.sep.gob.mx/storage/recursos/CENNI/sdsu_leaders.pdf</b></a>.",
  },
  {
    id: 4,
    question:
      "¿Puedo programar mi examen directamente con Excellence Certification?",
    answer:
      "No, todo el proceso de inscripción, incluida la programación del examen, debe realizarse a través de UKODESH EDUCATION. No obstante, puedes ponerte en contacto con ellos, y con gusto te dirigirán nuevamente a nosotros para cualquier consulta o asistencia adicional.",
  },
  {
    id: 5,
    question: "¿Puedo volver a tramitar mi Certificado CENNI una vez pasada la fecha de vencimiento con mi certificado SDSU LEADER?",
    answer:
      "Aunque tu certificado SDSU LEADERS sigue siendo válido, para trámites ante la SEP será necesario contar con uno actualizado. La Secretaría de Educación Pública solicitará un certificado con fecha reciente una vez que haya transcurrido el tiempo de vencimiento establecido por ellos mismos. Esto es esencial para asegurar que los maestros que trabajan en instituciones educativas demuestren su competencia para enseñar de acuerdo al grado y nivel educativo en el que se desempeñan.",
  },
  {
    id: 6,
    question: "¿Puedo reprogramar mi examen si algo surge en mi agenda?",
    answer:
      "Sí, puedes reprogramar tu examen una vez, siempre y cuando lo hagas con al menos 72 horas de anticipación a la fecha originalmente programada.",
  },
  {
    id: 7,
    question: "¿El servicio cumple con estándares internacionales de calidad?",
    answer:
      "Sí, nuestro servicio está avalado por San Diego State  University y cumple con los estándares internacionales del MCER para la enseñanza y evaluación del idioma inglés.",
  },
  {
    id: 8,
    question: "¿Cuál es la diferencia entre una constancia CENNI y un certificado CENNI?",
    answer:
      "La diferencia radica en los instrumentos de evaluación utilizados. La constancia evalúa únicamente habilidades de comprensión, por lo que su fecha de vencimiento es anual. En cambio, el certificado evalúa tanto habilidades de comprensión como de producción (oral y escrita). Por esta razón, la SEP CENNI emite certificados con una validez de hasta 5 años.",
  },
  {
    id: 9,
    question: "¿Qué tipo de documento emite la SEP CENNI al obtener mi certificado SDSU LEADERS y cuál es la fecha de vencimiento?",
    answer:
      "La SEP CENNI emite un certificado digital como segundo documento oficial, con vigencia  desde un año hasta por 5 años, dependiendo del nivel obtenido por el candidato.",
  },
];

const Accordions = () => {
  const [expanded, setExpanded] = useState(0);
  return (
    <section className="flex box-border flex-col mt-12">
      {faqs.map((faq, index) => (
        <Accordion
          key={index}
          faq={faq}
          expanded={expanded}
          setExpanded={setExpanded}
        />
      ))}
    </section>
  );
};

export default Accordions;
