import React, { useContext } from "react";
import Plan from "./Plan";
import { BreakpointContext } from "../context/AppProvider";

const quickFeatures = {
  one: "Examen de certificación <b>SDSU LEADERS ONLINE</b>",
  two: "Guía de envío <b>Fedex México</b>",
};
const exclusiveFeatures = {
  one: "Examen de certificación <b class='text-white'>SDSU LEADERS ONLINE</b>",
  two: "Assesment",
  three: "<b class='text-white'>Tramite certificado CENNI</b>",
  four: "Guía de envío <b class='text-white'>Fedex México</b>",
};
const upgradeFeatures = {
  one: "<span class='-ml-5 flex flex-col text-center justify-center items-center'>Todo lo que incluye el<b style='color:rgb(79, 97, 255)' class='text-center'>Exclusive Certification Package<br></b><b style='color:#808080;font-size:24px'>+</b></span>",
  two: "1 Assesment Adicional",
  three: "Curso de inglés online de 120 horas (3 Meses).",
};

const Plans = () => {
  const { width, breakpointDesktop, breakpointMobile } =
    useContext(BreakpointContext);
  return (
    <section
      className="sm:min-h-[1400px] h-full bg-plans pb-24 px-12 flex justify-end items-center flex-col text-center"
    >
      <h1 
      id="plans" className={width > breakpointMobile ? 'text-4xl sm:text-6xl font-bold mb-3 pt-6 lg:text-white' : 'text-4xl sm:text-6xl font-bold mb-3 pt-6'}>
        Elige el mejor plan <br />
        de certificación de inglés
      </h1>
      <p className={width > breakpointMobile ? "text-xl mb-12 font-semibold max-w-xl lg:text-white" : "text-xl mb-12 font-semibold max-w-xl"}>
        Compara las opciones que tenemos para ti y elige la que mejor se adecue
        a tus necesidades
      </p>
      <div  className="flex mt-12 end-0 flex-col lg:flex-row">
        <Plan
          bgColor={"white"}
          borderColor={"#4F61FF"}
          title={"Quick Certification Package"}
          price={"$2,745.00 MXN"}
          priceLined={"$3,915.00 MXN"}
          features={quickFeatures}
          PayLink={"https://mpago.la/2uQnXv7"}
        />
        <Plan
          bgColor={"#4F61FF"}
          borderColor={"white"}
          title={"Exclusive Certification Package"}
          price={"$3,338.00 MXN"}
          priceLined={"$5,564.00 MXN"}
          features={exclusiveFeatures}
          purpled={true}
          PayLink={"https://mpago.la/2poapBM"}
          centered={true}
        />
        <Plan
          bgColor={"white"}
          borderColor={"#4F61FF"}
          title={"Professional Certification Upgrade"}
          price={"$4,450.00 MXN"}
          priceLined={"$7,414.00 MXN"}
          features={upgradeFeatures}
          PayLink={"https://mpago.la/1hpN3Sv"}
        />
      </div>
    </section>
  );
};

export default Plans;
