import React from "react";

const Note = () => {
  return (
    <section className="flex justify-center items-center px-12 pt-6">
      <p className="text-xs text-gray-500 text-center">
        Nota: Los costos mencionados son válidos exclusivamente dentro de la
        República Mexicana.
      </p>
    </section>
  );
};

export default Note;
