import React from "react";

const Chat = () => {
  return (
    <section
      style={{ zIndex: "-1" }}
      className="flex flex-col sm:flex-row  bg-yellow-400 sm:justify-between justify-center px-12 items-center sm:items-end py-20 mt-12 -mb-24"
    >
      <div className="flex flex-col text-center sm:text-left sm:max-w-xs">
        <h1 className="text-2xl text-white font-bold">
          ¿Necesitas atención personalizada?
        </h1>
        <h1 className="text-md text-white font-bold">
          Contactános y con gusto resolveremos tus dudas{" "}
        </h1>
      </div>
      <a
        style={{ backgroundColor: "#4F61FF" }}
        className="text-white px-10 py-5 rounded-md mt-8 sm:mt-0"
        target="_blank"
        href="https://wa.link/2n812t"
      >
        Habla con un ejecutivo
      </a>
    </section>
  );
};

export default Chat;
