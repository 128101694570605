import React from "react";
import { Slide } from "react-slideshow-image";
import { StarIcon } from "@heroicons/react/24/solid";

const reviews = [
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_2_m0fb8j.png",
    title:
      "Agradezco la atención, rapidez y calidez en su atención y servicios.",
    desc: "Por segunda ocasión lleve mi proceso de certificación con UKODESH EDUCATION, y al igual que la primera vez siempre estuvieron atentos y resolviendo todas mis dudas.",
    name: "Diana Barrón",
    location: "Chiapas",
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_3_qyqcmu.png",
    title: "Lo que más aprecio es el hecho que me ayuden a tramitar mi CENNI",
    desc: "Me ahorré gastos de viáticos, problemas logísticos y todo el desgaste implicado en los trámites que tendría que llevar a cabo por mi cuenta.Mi experiencia fué de lo más agradable.",
    name: "Luis Celin",
    location: "Tijuana",
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_4_nbrruu.png",
    title: "La experiencia fue gratificante debido a la fluidez del proceso",
    desc: "Sus asesores tienen una paciencia para responder tus dudas y son muy amables. Sin duda una experiencia que recomendare.",
    name: "Enrique Guzmán",
    location: "Guadalajara",
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_8_xrjeyu.png",
    title:
      "Su asesoría previa a presentar mi examen de certificación fue de bastante ayuda y se los agradezco",
    desc: "El feedback que me proporcionaron de mi examen previo al de certificación evitó que quedara en un nivel inferior al que buscaba, tome el curso de preparación y gracias a eso obtuve el nivel C1 y la validez CENNI por 5 años.",
    name: "Fernando García",
    location: "Monterrey",
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992987/ukodesh-landing/Group_9_qpvzy9.png",
    title:
      "La flexibilidad en las fechas me permitió poder programar de acuerdo a mis tiempos",
    desc: "Sin duda un proceso sencillo el llevarlo desde la comodidad de mi casa y recibir mi certificado vía fedex en mi domicilio me ahorró mucho tiempo. Agradezco la atención por parte de mi asesor siempre me mantuvo tranquila.",
    name: "Daniela Rodríguez",
    location: "Ensenada",
  },
  {
    picture:
      "https://res.cloudinary.com/droimgtqi/image/upload/v1701992988/ukodesh-landing/Group_10_ljpltp.png",
    title: "Me sentí súper segura y acompañada aprendiendo a mi ritmo",
    desc: "Me sentí muy tranquila aprendiendo a mi ritmo hasta llegar al certificado. Entré con miedo por que no sabía mucho inglés pero ahora puedo decir que lo entiendo y lo hablo.",
    name: "Nahomy Colin",
    location: "Querétaro",
  },
];

const Reviews = () => {
  return (
    <section>
      <img
        className="bg-reviews"
        src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986630/ukodesh-landing/Light_Top_qaowtf.png"
      />
      <div className="flex flex-col items-center justify-center">
        <div className="flex flex-col items-center text-center justify-center -mt-8">
          <h1 className="text-4xl sm:text-6xl font-bold mb-4 mt-14">
            +5000 Candidatos Certificados
          </h1>
          <h1 className="text-xl font-bold">
            Conoce la opinión de nuestros estudiantes
          </h1>
        </div>
      </div>
      <div className="reviews-wrapper mt-12 sm:mt-20">
        <Slide autoplay={false} indicators={true}>
          {reviews.map((review, index) => (
            <div className="flex justify-center items-center p-2" key={index}>
              <div className="shadow-md shadow-slate-400 flex justify-center flex-col items-center rounded-lg bg-white max-w-lg p-10">
                <div className="flex flex-col border-b pb-5 mb-5">
                  <h1 className="font-bold text-lg mb-3">{review.title}</h1>
                  <p className="text-sm">{review.desc}</p>
                </div>
                <img
                  className="max-w-[100px] mb-2"
                  src={review.picture}
                  alt=""
                />
                <div className="flex">
                  <StarIcon className="mr-2" width={16} fill="#FFD059" />
                  <StarIcon className="mr-2" width={16} fill="#FFD059" />
                  <StarIcon className="mr-2" width={16} fill="#FFD059" />
                  <StarIcon className="mr-2" width={16} fill="#FFD059" />
                  <StarIcon width={16} fill="#FFD059" />
                </div>
                <p className="mt-3">
                  {review.name} | {review.location}
                </p>
              </div>
            </div>
          ))}
        </Slide>
      </div>
    </section>
  );
};

export default Reviews;
