import React from "react";
import Accordions from "./Accordions";

const FAQs = () => {
  return (
    <section className="flex flex-col justify-center items-center py-24 px-12">
      <div className="flex flex-col justify-center items-center">
        <h1 className="text-4xl sm:text-6xl font-bold mb-4 text-center">¿Tienes preguntas?</h1>
        <h1 className="text-2xl font-semibold text-center">Te ayudamos con las respuestas</h1>
      </div>
      <div className="box-border flex flex-col justify-center mt-10 items-center">
        <img className="mr-0 my-8 sm:mr-14 max-w-[280px]" src="https://res.cloudinary.com/droimgtqi/image/upload/v1701986631/ukodesh-landing/Group_176_sc5oie.png" alt="" />
        <Accordions />
      </div>
    </section>
  );
};

export default FAQs;
