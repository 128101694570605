import { createContext, useEffect } from "react";
import { useWidth } from "../hooks/useWidth";

export const BreakpointContext = createContext();

export const BreakpointProvider = ({children}) => {
    const width = useWidth();
    const breakpointDesktop = 1024;
    const breakpointMobile = 639;

    return(
        <BreakpointContext.Provider value={{width, breakpointDesktop, breakpointMobile}}>
            {children}
        </BreakpointContext.Provider>
    )
}
